import {
  Box,
  Button,
  DialogContentText,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  useTheme,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import BackIcon from "@material-ui/icons/ArrowBackIos";
import { useFormik } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import useGeneralStyle from "../assets/css/general";
import { httpClient } from "../config/httpClient";
import useCallbackStatus from "../hooks/useCallbackStatus";
import Modal from "../MuiComponent/Modal";
import TextField from "../MuiComponent/TextField";
import { currency } from "../utils/utility";
// import useCallbackStatus from "../../hooks/useCallbackStatus";

const useStyles = makeStyles((theme2) => ({
  root: {
    width: "100%",
    marginTop: "25px",
    overflowX: "auto",
    backgroundColor: "white",
    border: "1px solid #caced5",
  },
  container: {
    height: "440px",
    width: "100%",
  },
  inputLabel: {
    color: "color",
    fontSize: "13px",
    padding: "10px 0px",
  },
  activeFilter: {
    backgroundColor: theme2.palette.primary.main,
    color: "white",
    fontWeight: 600,
  },
}));

function Row(props) {
  const { data } = props;
  const [open, setOpen] = React.useState(false);
  let options = [];

  return (
    <React.Fragment>
      <TableRow>
        {/* <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell> */}
        <TableCell>
          {data.name + " "}
          {(() => {
            if (Array.isArray(data.options) == false) {
              let string = [];

              Object.keys(data.options).map((key) => {
                string.push(`${key}: ${data.options[key][0].value}, `);
              });

              return `[ ${string.join(", ")}]`;
            }
          })()}
        </TableCell>
        {/* <TableCell>{data.description}</TableCell> */}
        <TableCell component="th" scope="row">
          {data.quantity}
        </TableCell>
        <TableCell>
          {currency({
            value:
              parseFloat(data.product_price) + parseFloat(data.options_price),
          })}
        </TableCell>
        <TableCell>{currency({ value: data.total_price })}</TableCell>
      </TableRow>
    </React.Fragment>
  );
}

function DetailView({ data, onBack }) {
  const theme = useTheme();
  const classes = useStyles();
  let history = useHistory();
  const globalClasses = useGeneralStyle();
  const updateApiStatus = useCallbackStatus();
  const fetchApiStatus = useCallbackStatus();
  const [modal, setModal] = React.useState(false);
  const [order, setOrder] = React.useState(10);
  const [deliveryStaff, serDeliveryStaff] = useState([]);

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    resetForm,
    submitForm,
    setFieldValue,
  } = useFormik({
    initialValues: {
      status: "placed",
      tracking_id: "",
      delivery_boy_id: "",
      reason: "",
    },
    onSubmit: () => {
      updateStatus();
    },
  });

  const getDeliveryStaff = async () => {
    await fetchApiStatus.run(
      httpClient("POST", `/partner/getUsers`, {
        body: {
          user_type: "delivery_staff",
        },
      }).then((result) => {
        console.log("Delivery Staff: ", result);
        const {
          data: { data },
        } = result;

        serDeliveryStaff(data);
      })
    );
  };

  useEffect(() => {
    getDeliveryStaff();
  }, []);

  const updateStatus = async () => {
    await updateApiStatus.run(
      httpClient("POST", "/partner/updatedStatus", {
        body: {
          status: values.status,
          id: data.id,
          tracking_id: values.tracking_id,
          delivery_boy_id: values.delivery_boy_id,
        },
      })
        .then((result) => {
          console.log("Order", result);
          setModal(false);
          onBack();
        })
        .catch((er) => {
          console.log("ERROR ", er);
        })
    );
  };

  return (
    <>
      <Modal
        title="Update Status"
        height="300px"
        maxWidth="md"
        width="45%"
        closeBtnText="close"
        saveBtnText="save"
        saveHandler={submitForm}
        loading={updateApiStatus.isPending}
        closeHandler={() => {
          setModal(!modal);
        }}
        status={modal}
      >
        <DialogContentText id="alert-dialog-description">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box display="flex" flexDirection="column">
                <InputLabel className={globalClasses.inputLabel}>
                  Status
                </InputLabel>
                <Select
                  value={values.status}
                  variant="outlined"
                  fullWidth
                  onChange={(event) => {
                    setFieldValue("status", event.target.value);
                  }}
                >
                  <MenuItem disabled value="placed">
                    Placed
                  </MenuItem>
                  <MenuItem value="shipped">Shipped</MenuItem>
                  <MenuItem value="cancelled">Cancelled</MenuItem>
                </Select>
              </Box>
              <Box>
                {values.status == "shipped" && (
                  <>
                    <InputLabel className={globalClasses.inputLabel}>
                      Delivery Boy
                    </InputLabel>
                    <Select
                      name="delivery_boy_id"
                      id="delivery_boy_id"
                      value={values.delivery_boy_id}
                      variant="outlined"
                      fullWidth
                      onChange={(event) => {
                        setFieldValue("delivery_boy_id", event.target.value);
                      }}
                    >
                      <MenuItem disabled value="">
                        Select
                      </MenuItem>
                      {deliveryStaff.map((deliveryBoy) => {
                        return (
                          <MenuItem value={deliveryBoy.id}>
                            {deliveryBoy.name} {deliveryBoy.last_name} {}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <InputLabel className={globalClasses.inputLabel}>
                      Traking Id
                    </InputLabel>

                    <TextField
                      name="tracking_id"
                      placeholder="Traking Id"
                      error={touched.tracking_id && errors.tracking_id}
                      helperText={touched.tracking_id && errors.tracking_id}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.tracking_id}
                      variant="outlined"
                    />
                  </>
                )}
                {values.status == "cancelled" && (
                  <>
                    <InputLabel className={globalClasses.inputLabel}>
                      Reason
                    </InputLabel>
                    <TextField
                      name="reason"
                      placeholder="Reason"
                      // error={touched.note && errors.note}
                      // helperText={touched.note && errors.note}
                      // onChange={handleChange}
                      // onBlur={handleBlur}
                      // value={values.note}
                      // disabled={!isEditMode}
                      multiline
                      variant="outlined"
                    />
                  </>
                )}
              </Box>
            </Grid>
          </Grid>
        </DialogContentText>
      </Modal>

      <Box>
        <Grid container style={{ padding: theme.spacing(3) }}>
          <Grid item xs={12}>
            <Box
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "10px",
              }}
            >
              <Box display="flex">
                <Box>
                  <BackIcon
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      onBack();
                    }}
                  />
                </Box>
                <Box fontSize="18px">Back</Box>
              </Box>
              <Box>
                {data.order_status == "placed" && (
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      setModal(true);
                    }}
                  >
                    Update
                  </Button>
                )}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Paper style={{ padding: "15px" }} className={classes.root}>
              <Box>
                <Grid container>
                  <Grid item xs={4} style={{ lineHeight: "1.7" }}>
                    <Box>Billing Address</Box>
                    <Box fontWeight="600">{data.billing_address.name}</Box>
                    <Box>{data.billing_address.line1}</Box>
                    <Box>{data.billing_address.city}</Box>
                    <Box>{data.billing_address.mobile}</Box>
                    <Box>{data.billing_address.country}</Box>
                  </Grid>
                  <Grid item xs={4} style={{ lineHeight: "1.7" }}>
                    <Box>Shipping Address</Box>
                    <Box fontWeight="600">{data.shipping_address.name}</Box>
                    <Box>{data.shipping_address.line1}</Box>
                    <Box>{data.shipping_address.city}</Box>
                    <Box>{data.shipping_address.mobile}</Box>
                    <Box>{data.shipping_address.country}</Box>
                  </Grid>
                  <Grid item xs={4} style={{ lineHeight: "1.7" }}>
                    {/* <Box fontWeight="600">Invoice #002612</Box> */}
                    <Box></Box>
                    <Box>
                      <span style={{ fontWeight: "600" }}>Order ID: </span>
                      {data.id}
                    </Box>
                    <Box>
                      <span style={{ fontWeight: "600" }}>Order Date: </span>
                      {moment(data.updated_at).format("DD MMM YYYY")}
                    </Box>
                    {/* <Box>
                      <span style={{ fontWeight: "600" }}>Account: </span>
                      968-34567
                    </Box> */}
                  </Grid>
                  <Grid item xs={12} style={{ paddingTop: "35px" }}>
                    <TableContainer component={Paper}>
                      <Table aria-label="collapsible table">
                        <TableHead>
                          <TableRow>
                            {/* <TableCell /> */}
                            <TableCell>Product</TableCell>
                            {/* <TableCell>Description</TableCell> */}
                            <TableCell>Qty</TableCell>
                            <TableCell>Price</TableCell>
                            <TableCell>Total</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {data.cart.items.map((data) => (
                            <Row key={data.id} data={data} />
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>

                  <Grid
                    item
                    xs={6}
                    style={{ padding: "20px 20px", lineHeight: "1.8" }}
                  >
                    <Box fontSize="20px">Payment Methods:</Box>
                    <Box>
                      Etsy doostang zoodles disqus groupon greplin oooj voxy
                      zoodles, weebly ning heekya handango imeem plugg dopplr
                      jibjab, movity jajah plickers sifteo edmodo ifttt zimbra.
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    style={{ padding: "20px 90px", lineHeight: "3" }}
                  >
                    {/* <Box fontSize="20px" borderBottom="1px solid black">
                      Amount Due 2/22/2022
                    </Box> */}
                    <Box display="flex" borderBottom="1px solid black">
                      <Box flex={1} fontWeight="600">
                        SubTotal:
                      </Box>
                      <Box flex={1}>{currency({ value: data.subtotal })}</Box>
                    </Box>
                    <Box display="flex" borderBottom="1px solid black">
                      <Box flex={1} fontWeight="600">
                        GST
                      </Box>
                      <Box flex={1}>{currency({ value: data.gst })}</Box>
                    </Box>
                    <Box display="flex" borderBottom="1px solid black">
                      <Box flex={1} fontWeight="600">
                        Shipping:
                      </Box>
                      <Box flex={1}>
                        {currency({ value: data.shipping_charge })}
                      </Box>
                    </Box>
                    <Box display="flex" borderBottom="1px solid black">
                      <Box flex={1} fontWeight="600">
                        Total:
                      </Box>
                      <Box flex={1}>{currency({ value: data.cart_total })}</Box>
                    </Box>
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        paddingTop: "15px",
                      }}
                    >
                      <Button
                        onClick={() => {
                          history.push("/orders/invoice", { data });
                          // window.open("/orders/invoice");
                        }}
                        variant="contained"
                        style={{ marginRight: "7px" }}
                        color="primary"
                      >
                        Generate PDF
                      </Button>
                      {/* <Button variant="contained">Submit Payment</Button> */}
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default DetailView;
