import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import {
  fetchUser,
  fetchUserList,
  getServiceBills,
  setConfigurations,
  setCustomerById,
  setFormStructure,
  setFormValidations,
  setGeneralApiData,
  setOrderData,
  setReducerData,
  setServiceBills,
  setUser,
  setUserList,
} from "../actions";
import { fetchData, makeRequest } from "../api";
import {
  FETCH_CITIES_DATA,
  FETCH_COUNTRY_DATA,
  FETCH_STATE_DATA,
  GENERAL_API_CALL,
  TYPES,
  UPDATE_BUSINESS_CONFIGURATION,
} from "../constants";
import { selectGeneralReducer } from "../selectors";
import { convertBackendErrorResponse } from "../../utils/utility";

function* generalApiCall({ payload }) {
  try {
    const data = yield call(fetchData, payload);
    yield put(setGeneralApiData({ data: data, key: payload.key }));
  } catch (er) {
    console.log("GENERAL API ERROR:", er);
  }
}

function* getCitiesData({ payload }) {
  try {
    const data = yield call(fetchData, {
      method: "POST",
      endpoint: "/common/getCities",
      apiBody: payload,
    });

    yield put(setReducerData({ data: data.data.data, property: "cities" }));
  } catch (er) {
    console.log("GENERAL API ERROR:", er);
  }
}

function* getStateData({ payload }) {
  try {
    const data = yield call(fetchData, {
      method: "POST",
      endpoint: "/common/getStates",
      apiBody: payload,
    });

    yield put(setReducerData({ data: data.data.data, property: "states" }));
  } catch (er) {
    console.log("GENERAL API ERROR:", er);
  }
}

function* getCountriesData({ payload }) {
  try {
    const data = yield call(fetchData, {
      method: "POST",
      endpoint: "/common/getCountry",
      apiBody: payload | {},
    });

    yield put(setReducerData({ data: data.data.data, property: "countries" }));
  } catch (er) {
    console.log("GENERAL API ERROR:", er);
  }
}

function* updateConfigurationsApiCall({ payload }) {
  let {
    userData: { business_id },
  } = yield select(selectGeneralReducer);

  try {
    yield call(makeRequest, {
      method: "PUT",
      endpoint: `/partner/updateConfiguration/${business_id}`,
      apiBody: payload,
    });

    yield put(setConfigurations(payload));
  } catch (er) {
    console.log("GENERAL API ERROR:", er);
  }
}

function* updateBusinessConfigurationsData({ payload }) {
  console.log("payload:", payload);
  try {
    yield call(makeRequest, {
      method: "POST",
      endpoint: "/partner/updateBusinessConfiguration",
      apiBody: payload,
    });

    yield put(fetchUser());
  } catch (er) {
    console.log("GENERAL API ERROR:", er);
  }
}

function* getUserData({ payload }) {
  try {
    const { data } = yield call(fetchData, {
      method: "GET",
      endpoint: "/partner/user",
      apiBody: payload || {},
    });

    yield put(setUser(data));
  } catch (er) {
    console.log("GENERAL API ERROR:", er);
  }
}

function* getFormStructureApiCall({ payload }) {
  try {
    const data = yield call(fetchData, {
      method: "POST",
      endpoint: "/partner/getFormStructure",
      apiBody: payload,
    });

    yield put(setFormStructure(data));
  } catch (er) {
    console.log("GENERAL API ERROR:", er);
  }
}

function* addCustomerApiCall({ payload }) {
  let {
    userData: { business_id },
  } = yield select(selectGeneralReducer);

  try {
    yield call(makeRequest, {
      method: "POST",
      endpoint: "/partner/addCustomer",
      apiBody: payload,
    });

    yield put(
      fetchUserList({
        page: 1,
        page_size: 10,
        search_string: null,
        user_type: "service_customers",
        business_id: business_id,
      })
    );

    yield put(setReducerData({ data: false, property: "modal_status" }));
  } catch (er) {
    yield put(setFormValidations(convertBackendErrorResponse(er.errors)));
  }
}

function* updateCustomerApiCall({ payload }) {
  let {
    userData: { business_id },
  } = yield select(selectGeneralReducer);

  try {
    const data = yield call(makeRequest, {
      method: "POST",
      endpoint: "/partner/updateCustomer",
      apiBody: payload,
    });

    console.log("UPDATE CUSOTMER:", data);

    yield put(
      fetchUserList({
        page: 1,
        page_size: 10,
        search_string: null,
        user_type: "service_customers",
        business_id: business_id,
      })
    );

    yield put(setReducerData({ data: false, property: "modal_status" }));
  } catch (er) {
    yield put(setFormValidations(convertBackendErrorResponse(er.errors)));
  }
}

function* fetchUserListApiCall({ payload }) {
  try {
    const data = yield call(makeRequest, {
      method: "POST",
      endpoint: "/partner/getUsers",
      apiBody: payload,
    });

    console.log("USERSS HERE:", data.data);
    yield put(setUserList({ data: data.data, user_type: payload.user_type }));
  } catch (er) {
    console.log("GENERAL API ERROR:", er);
  }
}

function* getServiceBillsApiCall({ payload }) {
  let { pageNumber, pageSize, paymentMode, search, filter } = payload;

  let {
    userData: { business_id },
  } = yield select(selectGeneralReducer);

  try {
    const data = yield call(makeRequest, {
      method: "GET",
      endpoint: `/partner/service-bills?page_size=${pageSize}&payment_status=${paymentMode}&type=partner&page=${pageNumber}&search_string=${search}&pagination_required=true&business_id=${business_id}`,
      apiBody: {},
    });

    console.log("SERVICE BILLS LIST:", data);
    yield put(setServiceBills(data.data));
  } catch (er) {
    console.log("GENERAL API ERROR:", er);
  }
}

function* getCustomerById({ payload }) {
  let { id, user_type, paymentMode } = payload;

  let {
    userData: { business_id },
  } = yield select(selectGeneralReducer);

  try {
    const data = yield call(makeRequest, {
      method: "GET",
      endpoint: `/partner/userData/${id}?business_id=${business_id}&type=${user_type}`,
      apiBody: {},
    });

    yield put(setCustomerById(data.data));
    yield put(
      getServiceBills({
        pageSize: 10,
        pageNumber: 1,
        search: data.data.mobile_number,
        paymentMode,
      })
    );
  } catch (er) {
    console.log("GENERAL API ERROR:", er);
  }
}

function* updateServiceBillTransactionApiCall({ payload }) {
  let { paymentMode, search } = payload;

  let {
    userData: { id, business_id },
  } = yield select(selectGeneralReducer);

  try {
    const data = yield call(makeRequest, {
      method: "POST",
      endpoint: `/partner/updateServiceBillTransaction`,
      apiBody: {
        partner_id: id,
        business_id: business_id,
      },
    });

    yield put(
      getServiceBills({
        pageSize: 10,
        pageNumber: 1,
        search,
        paymentMode,
      })
    );
  } catch (er) {
    console.log("GENERAL API ERROR:", er);
  }
}

function* getOnlineOrderById({ payload }) {
  let { order_id, callback } = payload;

  try {
    const data = yield call(makeRequest, {
      method: "GET",
      endpoint: `/partner/online-sales/${order_id}`,
      apiBody: {},
    });
    console.log("GET ORDER BY ID:", data);

    yield put(setOrderData(data));

    callback();
  } catch (er) {
    console.log("GENERAL API ERROR:", er);
  }
}

function* getOrderByIdApiCall({ payload }) {
  let { order_id, callback } = payload;

  try {
    const data = yield call(makeRequest, {
      method: "GET",
      endpoint: `/partner/sales/${order_id}`,
      apiBody: {},
    });
    console.log("GET ORDER BY ID:", data);

    yield put(setOrderData(data));

    callback();
  } catch (er) {
    console.log("GENERAL API ERROR:", er);
  }
}

function* deletePOSBillApiCall({ payload }) {
  console.log("payload:", payload);
  let { id, callback } = payload;

  try {
    const data = yield call(makeRequest, {
      method: "DELETE",
      endpoint: `/partner/removePOSBill/${id}`,
      apiBody: {},
    });

    callback();
  } catch (er) {
    console.log("GENERAL API ERROR:", er);
  }
}

export function* GeneralSaga() {
  yield takeEvery(FETCH_COUNTRY_DATA, getCountriesData);
  yield takeEvery(FETCH_STATE_DATA, getStateData);
  yield takeEvery(FETCH_CITIES_DATA, getCitiesData);
  yield takeEvery(TYPES.FETCH_USER_DATA, getUserData);
  yield takeEvery(GENERAL_API_CALL, generalApiCall);
  yield takeEvery(TYPES.ADD_CUSTOMER, addCustomerApiCall);
  yield takeEvery(TYPES.FETCH_USERS_LIST, fetchUserListApiCall);
  yield takeEvery(TYPES.UPDATE_CUSTOMER, updateCustomerApiCall);
  yield takeEvery(TYPES.GET_FORM_STRUCTURE, getFormStructureApiCall);
  yield takeEvery(TYPES.FETCH_SERVICE_BILLS, getServiceBillsApiCall);
  yield takeEvery(TYPES.GET_CUSTOMER_BY_ID, getCustomerById);
  yield takeEvery(
    TYPES.UPDATE_SERVICE_BILL_TRANSACTION,
    updateServiceBillTransactionApiCall
  );
  yield takeEvery(
    UPDATE_BUSINESS_CONFIGURATION,
    updateBusinessConfigurationsData
  );
  yield takeEvery(TYPES.DELETE_POS_BILL, deletePOSBillApiCall);
  yield takeEvery(TYPES.UPDATE_CONFIGURATIONS, updateConfigurationsApiCall);
  yield takeEvery(TYPES.GET_ORDER_BY_ID, getOrderByIdApiCall);
  yield takeLatest(TYPES.GET_ONLINE_ORDER_BY_ID, getOnlineOrderById);
}
