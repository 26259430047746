import {
  Box,
  Button,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  useTheme,
  TableRow,
  TableCell,
  IconButton,
  Menu,
  DialogContentText,
} from "@material-ui/core";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { useHistory } from "react-router-dom";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import TextField from "../../MuiComponent/TextField";
import useGeneralStyle from "../../assets/css/general";
import Datatable from "../../MuiComponent/Datatable";
import useCallbackStatus from "../../hooks/useCallbackStatus";
import { httpClient } from "../../config/httpClient";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import Modal from "../../MuiComponent/Modal";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";
import _ from "lodash";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { useAuth } from "../../providers/AuthProvider";
import HasBusinessDetailsCheck from "../../components/HasBusinessDetailsCheck";
import { currency } from "../../utils/utility";
import { useQuery } from "@tanstack/react-query";
import { API_KEYS } from "../../store/constants";

const tableHeader = [
  { id: "name", align: "left", label: "IMAGE", minWidth: 170 },
  { id: "name", align: "left", label: "NAME", minWidth: 170 },
  {
    id: "population",
    label: "FEATURED",
    minWidth: 170,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "size",
    label: "PRICE",
    minWidth: 170,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "density",
    label: "MANAGE",
    minWidth: 170,
    align: "left",
    format: (value) => value.toFixed(2),
  },
];

const useStyles = makeStyles((theme2) => ({
  root: {
    width: "100%",
    marginTop: "25px",
    overflowX: "auto",
    backgroundColor: "white",
    border: "1px solid #caced5",
  },
  container: {
    height: "440px",
    width: "100%",
  },
  inputLabel: {
    color: "color",
    fontSize: "13px",
    padding: "10px 0px",
  },
  activeFilter: {
    backgroundColor: theme2.palette.primary.main,
    color: "white",
    fontWeight: 600,
  },
}));

const ProductDatatable = forwardRef(({}, ref) => {
  useImperativeHandle(ref, () => ({
    getAllProductsList,
  }));

  const location = useLocation();
  const theme = useTheme();
  const classes = useStyles();
  const auth = useAuth();
  const history = useHistory();
  const [productList, setProductsList] = useState([]);
  const [totalPages, setTotalPages] = useState(10);
  const [selectedData, setselectedData] = useState({});
  const getProductsApiStatus = useCallbackStatus();
  const actionApiStatus = useCallbackStatus();
  const [search, setSearchKey] = useState(null);
  const [modalDel, setModalDel] = React.useState(false);
  const notification = useSnackbar();
  const [confirmation, setConfirmation] = useState(false);
  const { userData } = useSelector((state) => state.general);

  const deleteProduct = async () => {
    await actionApiStatus.run(
      httpClient("DELETE", `/partner/product/${selectedData.id}`, {
        body: {},
      }).then((result) => {
        getAllProductsList();
        setModalDel(false);
        notification.enqueueSnackbar("Product Deleted Successfully", {
          variant: "success",
        });
      })
    );
  };

  const getAllProductsList = useCallback(
    async (pageSize = 10, pageNumber = 1, search = null) => {
      const apiBody = {
        page: pageNumber,
        page_size: pageSize,
        search_string: search,
        business_id: auth.userData.business_id,
      };

      if (apiBody.search_string === "") {
        delete apiBody.search_string;
      }

      await getProductsApiStatus.run(
        httpClient("POST", "/partner/getProducts", {
          body: apiBody,
        })
          .then((result) => {
            console.log("Result All Products: ", result);

            const {
              data: { data, total },
            } = result;

            console.log("TOTAL RECOREDS All Products: ", total);
            setProductsList(data);
            setTotalPages(total);
          })
          .catch((er) => {
            throw new Error(er);
          })
      );
    },
    []
  );

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (search !== null) getAllProductsList(10, 1, search);
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [search]);

  const ModalHeaderDel = () => {
    return (
      <>
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>Delete</Box>
        </Box>
      </>
    );
  };

  return (
    <>
      <HasBusinessDetailsCheck
        status={confirmation}
        onClose={() => {
          if (_.isNull(userData?.businessDetails?.account_details))
            history.push("/profile/account-details");
          else history.push("/profile/business-details");
        }}
      />
      <Modal
        headerComponent={ModalHeaderDel}
        height="180px"
        buttonAlign="center"
        width="100%"
        closeBtnText="close"
        saveBtnText="save"
        loading={actionApiStatus.isPending}
        saveHandler={() => {
          deleteProduct();
        }}
        closeHandler={() => {
          setModalDel(!modalDel);
        }}
        status={modalDel}
      >
        <DialogContentText id="alert-dialog-description">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box>Are You sure you want to delete.</Box>
            </Grid>
          </Grid>
        </DialogContentText>
      </Modal>
      <Grid container style={{ padding: theme.spacing(3) }}>
        <Grid
          style={{ display: "flex", alignItems: "center", padding: "10px 0px" }}
          item
          xs={5}
        >
          <Box style={{ paddingRight: "20px" }}>Search</Box>
          <TextField
            onChange={(e) => setSearchKey(e.target.value)}
            variant="outlined"
            style={{ width: "100%" }}
            inputProps={{
              style: { padding: "12px", backgroundColor: "white" },
            }}
            placeholder="by Name"
          />
        </Grid>
        <Grid
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row-reverse",
            padding: "10px 0px",
          }}
          item
          xs={7}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              if (
                _.isNull(userData?.businessDetails) ||
                _.isNull(userData?.businessDetails?.account_details)
              )
                setConfirmation(true);
              else {
                history.push("/products/add");
                // addMeetingStatus("addRecord");
              }
            }}
          >
            Add Product
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.root}>
            <Datatable
              loading={getProductsApiStatus.isPending}
              totalRecords={totalPages}
              tableHeader={tableHeader}
              getDataList={getAllProductsList}
            >
              {productList.map((product, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell>
                      <img
                        height="50px"
                        width="50px"
                        src={product.cover_image}
                      />
                    </TableCell>
                    <TableCell>{product.name}</TableCell>
                    <TableCell>{product.is_featured}</TableCell>
                    <TableCell>
                      {currency({
                        currency: userData.currency,
                        value: product.product_data.pos_price,
                      })}{" "}
                      {/* {currency({
                        currency: userData.currency,
                        value: product.product_data.offer_price,
                      })}{" "}
                      <strike>
                        {currency({
                          currency: userData.currency,
                          value: product.product_data.retail_price,
                        })}
                      </strike> */}
                    </TableCell>
                    <TableCell>
                      <PopupState variant="popover" popupId="demo-popup-menu">
                        {(popupState) => (
                          <React.Fragment>
                            <IconButton {...bindTrigger(popupState)}>
                              <MoreHorizIcon />
                            </IconButton>
                            <Menu {...bindMenu(popupState)}>
                              <MenuItem
                                onClick={() => {
                                  history.push(`/products/${product.id}/edit`);
                                  popupState.setOpen(false);
                                }}
                              >
                                Edit
                              </MenuItem>
                              <MenuItem
                                onClick={() => {
                                  setselectedData(product);
                                  setModalDel(true);
                                  popupState.setOpen(false);
                                }}
                              >
                                Delete
                              </MenuItem>
                            </Menu>
                          </React.Fragment>
                        )}
                      </PopupState>
                    </TableCell>
                  </TableRow>
                );
              })}
            </Datatable>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
});

export default ProductDatatable;
