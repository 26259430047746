export const SET_USER = "SET_USER";
export const IS_COMPONENT_BUSY = "IS_COMPONENT_BUSY";

export const SET_PARENT_CATEGORIES = "SET_PARENT_CATEGORIES";
export const SET_CATEGORIES = "SET_CATEGORIES";
export const GENERAL_API_CALL = "GENERAL_API_CALL";
export const FETCH_STATE_DATA = "FETCH_STATE_DATA";
export const SET_REDUCER_DATA = "SET_REDUCER_DATA";
export const GENERAL_API_CALL_DATA = "GENERAL_API_CALL_DATA";
export const FETCH_CITIES_DATA = "FETCH_CITIES_DATA";
export const FETCH_COUNTRY_DATA = "FETCH_COUNTRY_DATA";
export const UPDATE_BUSINESS_CONFIGURATION = "UPDATE_BUSINESS_CONFIGURATION";

export const TYPES = {
  GET_ONLINE_ORDER_BY_ID: "order/GET_ONLINE_ORDER_BY_ID",
  SET_LOADER:"loader/SET_LOADER",
  DELETE_POS_BILL:"order/DELETE_POS_BILL",
  GET_ORDER_BY_ID:"order/GET_ORDER_BY_ID",
  SET_ORDER_BY_ID:"order/SET_ORDER_BY_ID",
  UPDATE_CONFIGURATIONS: "user/business/UPDATE_CONFIGURATIONS",
  SET_CONFIGURATIONS: "user/business/SET_CONFIGURATIONS",
  SYNC_CACHE_DATA: "partner/SYNC_CACHE_DATA",
  FETCH_USER_DATA: "user/FETCH_USER_DATA",
  GET_FORM_STRUCTURE: "user/GET_FORM_STRUCTURE",
  SET_FORM_STRUCTURE: "user/SET_FORM_STRUCTURE",
  SET_USER_LIST: "user/SET_USER_LIST",
  ADD_CUSTOMER: "partner/ADD_CUSTOMER",
  UPDATE_CUSTOMER: "partner/UPDATE_CUSTOMER",
  FETCH_USERS_LIST: "user/FETCH_USERS_LIST",
  SET_FORM_VALIDATIONS: "form/SET_FORM_VALIDATIONS",
  FETCH_SERVICE_BILLS: "form/FETCH_SERVICE_BILLS",
  GET_CUSTOMER_BY_ID: "user/GET_CUSTOMER_BY_ID",
  SET_SERVICE_BILLS: "datatable/SET_SERVICE_BILLS",
  SET_CUSTOMER_BY_ID: "datatable/SET_CUSTOMER_BY_ID",
  UPDATE_SERVICE_BILL_TRANSACTION: "bill/UPDATE_SERVICE_BILL_TRANSACTION",
};

export const PERMISSIONS = {
  USER_MANAGEMENT: "User Management",
  TASK_MANAGEMENT: "Task Management",
  SERVICE_BILLS: "Service Bills",
  SERVICE_MANAGEMENT: "Service Management",
  ROLE_ACCESS: "Role Management",
  CATEGORY_MANAGEMENT: "Category Management",
  PRODUCT_MANAGEMENT: "Product Management",
  POINT_OF_SALE: "Point of Sale",
  REVIEW_RATING: "Reviews & Ratings",
  NEWSLETTER_INQUIREY: "Newsletters & Inquiries",
  PURCHASE_MANAGEMENT: "Purchase Management",
  BANNER_MANAGEMENT: "Banner Management",
  SLIDER_MANAGEMENT: "Slider Management",
  ORDER_MANAGEMENT: "Order Management",
  TRANSACTION_MANAGEMENT: "Transaction Management",
  CMS_MANAGEMENT: "CMS Management",
  COUPON_MANAGEMENT: "Coupon Management",
  SETTINGS: "Settings",
  STAFF_MANAGEMENT: "Staff Management",
  DASHBOARD: "Dashboard",
};

export const API_KEYS = {
  FETCH_PAGINATED_PRODUCTS: "products/fetch_paginated_products",
  FETCH_LOGGED_IN_USER: "user/FETCH_LOGGED_IN_USER",
};
