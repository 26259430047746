import {
  Typography,
  Box,
  Grid,
  Button,
  useTheme,
  DialogContentText,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import Header from "../../layouts/Header";
import { currency } from "../../utils/utility";
import { useAuth } from "../../providers/AuthProvider";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Modal from "../../MuiComponent/Modal";
import { useFormik } from "formik";
import useCallbackStatus from "../../hooks/useCallbackStatus";
import useGeneralStyle from "../../assets/css/general";
import { getOnlineOrderById } from "../../store/actions";
import { httpClient } from "../../config/httpClient";
import TextField from "../../MuiComponent/TextField";

var converter = require("number-to-words");

{
  /* <style>
.page-break {
    page-break-after: always;
}
table{
    border-collapse: collapse;
    width: 100%;
}
</style> */
}

function Invoice({ data, onBack, updateStatus }) {
  const history = useHistory();
  const location = useLocation();
  const theme = useTheme();
  const globalClasses = useGeneralStyle();
  const { userData } = useAuth();
  const [modal, setModal] = React.useState(false);
  const updateApiStatus = useCallbackStatus();
  const fetchApiStatus = useCallbackStatus();

  const {
    userData: { businessDetails },
  } = useSelector((state) => state.general);

  return (
    <>
      <Grid container style={{ padding: theme.spacing(3) }}>
        <Grid item xs={12}>
          <Box display={"flex"}>
            <Box flex={1}>
              <Button
                onClick={() => {
                  onBack();
                }}
              >
                <ArrowBackIosIcon /> Back
              </Button>
            </Box>
            <Box>
              <Button
                onClick={updateStatus}
                color="primary"
                variant="contained"
              >
                Update Status
              </Button>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box style={{ backgroundColor: "white" }}>
            <Box style={{ margin: "10px 10px", marginLeft: "20px" }}>
              {console.log(data)}
              <meta
                httpEquiv="Content-Type"
                content="text/html; charset=utf-8"
              />
              <style
                dangerouslySetInnerHTML={{
                  __html:
                    "\n        .page-break {\n            page-break-after: always;\n        }\n        table{\n            border-collapse: collapse;\n            width: 100%;\n        }\n",
                }}
              />
              <div style={{ textAlign: "center" }}>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    alignItems: "center",
                  }}
                >
                  <div style={{ fontWeight: "bold", fontSize: 22, flex: 1 }}>
                    TAX INVOICE
                  </div>
                  <div>(ORIGINAL FOR RECIPIENT)</div>
                </div>
              </div>
              <table border={1}>
                <tbody>
                  <tr>
                    <td colSpan={2}>
                      {_.upperCase(businessDetails?.name)}
                      <div>{businessDetails?.address}</div>
                      <div>
                        {businessDetails?.city_name +
                          ", " +
                          businessDetails?.state_name +
                          " (" +
                          businessDetails.pincode +
                          ")"}
                      </div>
                      <div> GSTIN: {businessDetails?.gst_number}</div>
                      <div>Mo: {businessDetails.contact_number}</div>
                      <div>Email: {businessDetails.email}</div>
                    </td>
                    <td rowSpan={2} colSpan={4}>
                      <table width="100%" height="200px">
                        <tbody>
                          <tr style={{ borderBottom: "1px solid" }}>
                            <td
                              style={{ borderRight: "1px solid" }}
                              width="50%"
                            >
                              Order ID
                            </td>
                            <td style={{ textAlign: "left" }}>
                              {data.cart_id}
                            </td>
                          </tr>
                          <tr style={{ borderBottom: "1px solid" }}>
                            <td
                              style={{ borderRight: "1px solid" }}
                              width="50%"
                            >
                              Date
                            </td>
                            <td style={{ textAlign: "left" }}>
                              {moment(data.created_at).format("DD MMM YYYY")}
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{ borderRight: "1px solid" }}
                              width="50%"
                            >
                              Mode
                            </td>
                            <td style={{ textAlign: "left" }}>
                              {_.capitalize(data.payment_mode)}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div>Buyer (Bill to)</div>
                      <Box fontWeight="600">{data?.name}</Box>
                      {/* <Box>{data.billing_address.line1}</Box>
                  <Box>{data.billing_address.city}</Box> */}
                      <Box>Mob: {data.mobile}</Box>
                      <Box>GSTIN: {data.business_id}</Box>
                      {/* <div>156, B-Block Sec-14 Udaipur (Raj.)</div>
                  <div>313001</div>
                  <div>Mo: 9460970462</div>
                  <div>Email: jainelectricals2022@gmail.com</div> */}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={5}>
                      <table>
                        <tbody>
                          <tr style={{ borderBottom: "1px solid" }}>
                            <td width="70px">S.No</td>
                            <td>Description of Goods</td>
                            <td>HSN/SAC</td>
                            <td>Quantity</td>
                            <td>Rate</td>
                            <td>Discount</td>
                            <td>GST</td>
                            <td>Rate (GST)</td>
                            <td>Amount (₹)</td>
                          </tr>
                          {data?.items.map((item, index) => (
                            <tr>
                              <td width="70px">{index + 1}</td>
                              <td>
                                {item.name + " "}
                                {Object.keys(item.options).length > 0 &&
                                  `[${Object.keys(item.options)
                                    .map((key) => {
                                      return `${key}: ${item.options[key].value}`;
                                    })
                                    .join(", ")}]`}
                              </td>
                              <td>{item.hsn}</td>
                              <td>{item.quantity}</td>
                              <td>
                                {currency({
                                  value: item.product_price,
                                  currency: userData.currency,
                                })}
                              </td>
                              <td>{item.discount}%</td>
                              <td>
                                {item.tax}%
                                {/* {currency({
                              value: item.total_price * (item.tax / 100),
                              currency: userData.currency,
                            })}{" "}
                            ({item.tax}%) */}
                              </td>
                              <td>
                                {" "}
                                {currency({
                                  value:
                                    item.product_price +
                                    (item.product_price * item.tax) / 100,
                                  currency: userData.currency,
                                })}
                              </td>
                              <td>
                                {currency({
                                  value: item.total_price,
                                  currency: userData.currency,
                                })}
                              </td>
                            </tr>
                          ))}
                          <tr style={{ padding: 5 }}>
                            <td colSpan={6} style={{ textAlign: "right" }}></td>
                            <td> {data?.cart?.cart_total} </td>
                          </tr>
                          <tr>
                            <td
                              style={{ textAlign: "right", paddingRight: 30 }}
                              colSpan={8}
                            >
                              CGST
                            </td>
                            <td>
                              {currency({
                                value: data?.total_taxable_amount / 2,
                                currency: userData.currency,
                              })}
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{ textAlign: "right", paddingRight: 30 }}
                              colSpan={8}
                            >
                              SGST
                            </td>
                            <td>
                              {currency({
                                value: data?.total_taxable_amount / 2,
                                currency: userData.currency,
                              })}
                            </td>
                          </tr>
                          <tr style={{ borderTop: "1px solid" }}>
                            <td
                              style={{
                                textAlign: "right",
                                fontWeight: "bold",
                                paddingRight: 30,
                              }}
                              colSpan={8}
                            >
                              Total
                            </td>
                            <td style={{ fontWeight: "bold" }}>
                              {currency({
                                value: data?.total_payable_with_tax,
                                currency: userData.currency,
                              })}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr style={{ borderTop: "1px solid" }}>
                    <td style={{ paddingRight: 30 }}>
                      Amount Chargeable (in words)
                    </td>
                  </tr>
                  <tr style={{ borderTop: "1px solid" }}>
                    <td style={{ paddingRight: 30, fontWeight: "bold" }}>
                      INR{" "}
                      {data?.total_payable_with_tax &&
                        _.startCase(
                          converter.toWords(data?.total_payable_with_tax)
                        )}{" "}
                      Only
                    </td>
                  </tr>
                  <tr style={{ borderTop: "1px solid" }}>
                    <td
                      style={{
                        paddingRight: 30,
                        borderRight: "1px solid",
                        width: "50%",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          height: 100,
                          flexDirection: "column",
                        }}
                      >
                        <div style={{ flex: 1 }}>
                          Company PAN: {businessDetails?.pancard}
                        </div>
                        <div>
                          <div>Declaration:</div>
                          <div style={{ fontStyle: "italic" }}>
                            We declare that this invoice shows the actual price
                            of the goods described and that all particulars are
                            true and correct.
                          </div>
                        </div>
                      </div>
                    </td>
                    <td style={{ paddingRight: 30 }} colSpan={2}>
                      <div>Company's Bank Details</div>
                      <div>
                        A/C Holder Name:{" "}
                        {businessDetails?.account_details?.account_holder_name}
                      </div>
                      <div>
                        Bank Name: {businessDetails?.account_details?.bank_name}
                      </div>
                      <div>
                        A/C No.:{" "}
                        {businessDetails?.account_details?.account_number}
                      </div>
                      <div>
                        IFS Code: {businessDetails?.account_details?.ifsc_code}
                      </div>
                    </td>
                  </tr>
                  <tr style={{ borderTop: "1px solid", height: 100 }}>
                    <td
                      style={{
                        paddingRight: 30,
                        borderRight: "1px solid",
                        width: "50%",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          height: 100,
                          flexDirection: "column",
                        }}
                      >
                        <div>Customer's Seal &amp; Signature</div>
                      </div>
                    </td>
                    <td
                      style={{ paddingRight: 30, textAlign: "right" }}
                      colSpan={2}
                    >
                      <div
                        style={{
                          display: "flex",
                          height: 100,
                          flexDirection: "column",
                        }}
                      >
                        <div style={{ flex: 1 }}>
                          For {businessDetails?.name}
                        </div>
                        <div>Authorised Signatory</div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export default Invoice;
