import React, { useCallback, useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { Box, Button, Typography, Grid, useTheme } from "@material-ui/core";
import Header from "../layouts/Header";
import TextField from "../MuiComponent/TextField";
import useGeneralStyle from "../assets/css/general";
import { useFormik } from "formik";
import {
  editSliderValidation,
  addSliderValidation,
} from "../validations/login-validate";
import { httpClient } from "../config/httpClient";
import useCallbackStatus from "../hooks/useCallbackStatus";
import { useEffect } from "react";
import { useSnackbar } from "notistack";
import Datatable from "../MuiComponent/Datatable";
import _ from "lodash";
import ConfirmationModal from "../MuiComponent/ConfirmationModal";
import { useHistory } from "react-router-dom";
import DetailView from "./DetailView";
import clsx from "clsx";
import { currency } from "../utils/utility";
import moment from "moment";
import queryString from "query-string";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const tableHeader = [
  { id: "date", align: "left", label: "Date", minWidth: 170 },
  { id: "id", align: "left", label: "Order Number", minWidth: 170 },
  { id: "name", align: "left", label: "Name", minWidth: 170 },
  { id: "items", align: "left", label: "Items", minWidth: 170 },
  { id: "mobile_number", align: "left", label: "Mobile Number", minWidth: 170 },
  { id: "manage", align: "left", label: "Manage", minWidth: 170 },
];

const useStyles = makeStyles((theme2) => ({
  root: {
    width: "100%",
    marginTop: "25px",
    overflowX: "auto",
    backgroundColor: "white",
    border: "1px solid #caced5",
  },
  container: {
    height: "440px",
    width: "100%",
  },
  inputLabel: {
    color: "color",
    fontSize: "13px",
    padding: "10px 0px",
  },
  activeFilter: {
    backgroundColor: theme2.palette.primary.main,
    color: "white",
    fontWeight: 600,
  },
}));

function CollectedOrders() {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  const [page, setPage] = React.useState(0);
  const [mode, setMode] = useState("add");
  const [view, setView] = useState("false");
  const notification = useSnackbar();
  const [confirmation, setConfirmation] = React.useState(false);
  const addSliderApiStatus = useCallbackStatus();
  const [search, setSearchKey] = useState("");
  const getBannersApiStatus = useCallbackStatus();
  const searchParams = useMemo(
    () => queryString.parse(location.search),
    [location.search]
  );
  const [filter, setFilter] = useState(searchParams.filter || "placed");
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [orderList, setOrderList] = React.useState([]);
  const [totalPages, setTotalPages] = React.useState(10);
  const [modal, setModal] = React.useState(false);
  const globalClasses = useGeneralStyle();
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const [orderDetail, setOrderDetail] = useState({
    cart: { items: [] },
    billing_address: {},
    shipping_address: {},
  });

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const theme = useTheme();

  const fileHandler = (file, alt) => {
    if (file && file !== "") {
      console.log("coming in if condition.", file);
      setValues({ ...values, image_name: file.name, image: file });
    }
  };

  //   const getCategories = async () => {
  //     const apiBody = {
  //       pagination_required: true,
  //     };

  //     await getCategoryListStatus.run(
  //       httpClient("POST", "/common/category", {
  //         body: apiBody,
  //       }).then((result) => {
  //         console.log("Result: ", result);

  //         const { data } = result;

  //         setCategoriesList(data);
  //       })
  //     );
  //   };

  const getOrderList = useCallback(
    async (pageSize = 10, pageNumber = 1) => {
      await getBannersApiStatus.run(
        httpClient(
          "GET",
          `/partner/getCollectedOrders?pageSize=${pageSize}&page=${pageNumber}&search_string=${search}&status=${filter}&pagination_required=true`,
          {
            body: {},
          }
        ).then((result) => {
          console.log("getSLiders List: ", result);

          const {
            data: { data, meta },
          } = result;

          setOrderList(data);
          setTotalPages(meta.total);
        })
      );
    },
    [search, filter]
  );

  const editBanner = async () => {
    let fd = new FormData();
    fd.append("id", values.id);
    fd.append("name", values.name);
    fd.append("link", values.link);
    fd.append("active", values.active);
    fd.append("type", values.type);

    if (typeof image == "object") fd.append("image", values.image);

    await addSliderApiStatus.run(
      httpClient("POST", "/admin/updateSlider", {
        body: fd,
      })
        .then((result) => {
          console.log("Result: ", result);
          resetForm();
          setModal(false);

          //   getSliders();

          notification.enqueueSnackbar("Slider Updated Successfully", {
            variant: "success",
          });
        })
        .catch((er) => {
          console.log("Error: ", er);

          notification.enqueueSnackbar(er.errors["name"], {
            variant: "error",
          });
        })
    );
  };

  const deleteBanner = async () => {
    await addSliderApiStatus.run(
      httpClient("POST", "/admin/deleteSlider", {
        body: {
          id: values.id,
        },
      })
        .then((result) => {
          console.log("Result: ", result);
          resetForm();
          setConfirmation(false);
          //   getSliders();

          notification.enqueueSnackbar("Slider Deleted Successfully", {
            variant: "success",
          });
        })
        .catch((er) => {
          console.log("Error: ", er);

          notification.enqueueSnackbar(er.errors["name"], {
            variant: "error",
          });
        })
    );
  };

  const addSlider = async () => {
    let fd = new FormData();
    fd.append("name", values.name);
    fd.append("link", values.link);
    fd.append("active", values.active);
    fd.append("type", values.type);
    fd.append("image", values.image);

    await addSliderApiStatus.run(
      httpClient("POST", "/admin/createSlider", {
        body: fd,
      })
        .then((result) => {
          console.log("Result: ", result);
          resetForm();
          setModal(false);

          //   getSliders();

          notification.enqueueSnackbar("Slider Added Successfully", {
            variant: "success",
          });
        })
        .catch((er) => {
          console.log("Error: ", er);

          notification.enqueueSnackbar(er.errors["name"], {
            variant: "error",
          });
        })
    );
  };

  let {
    values,
    touched,
    errors,
    setValues,
    resetForm,
    handleBlur,
    submitForm,
    handleChange,
  } = useFormik({
    initialValues: {
      name: "",
      //   category_id: "",
      link: "",
      type: "App",
      image: "",
      active: "Y",
    },
    onSubmit: () => {
      if (mode == "edit") editBanner();
      else if (mode == "delete") deleteBanner();
      else addSlider();
    },
    validationSchema:
      mode == "edit" || mode == "delete"
        ? editSliderValidation
        : addSliderValidation,
  });

  //   useEffect(() => {
  //     getCategories();
  //   }, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      getOrderList();
    }, 300);

    return () => clearTimeout(delayDebounceFn);
  }, [search, filter]);

  return (
    <>
      <ConfirmationModal
        height="150px"
        width="auto"
        title="Are you sure"
        status={confirmation}
        closeBtnText="cancel"
        saveBtnText="Delete"
        closeHandler={() => setConfirmation(!confirmation)}
        saveHandler={() => submitForm()}
      >
        Are you sure want to delete this category
      </ConfirmationModal>

      <Header>
        <Typography style={{ flexGrow: 1 }} variant="h6" noWrap>
          Collected Orders
        </Typography>
      </Header>

      <Box display="flex" style={{ backgroundColor: "white" }}>
        <Box style={{ margin: "10px 10px", marginLeft: "20px" }}>
          <Button
            disableElevation
            onClick={() => {
              setFilter("placed");
              history.replace("/collected-orders?filter=placed");
            }}
            className={clsx({
              [classes.activeFilter]: filter === "placed",
            })}
            variant="contained"
          >
            PLACED
          </Button>
        </Box>
        <Box style={{ margin: "10px 10px" }}>
          <Button
            disableElevation
            variant="contained"
            className={clsx({
              [classes.activeFilter]: filter === "picked",
            })}
            onClick={() => {
              setFilter("picked");
              history.replace("/collected-orders?filter=picked");
            }}
          >
            Picked
          </Button>
        </Box>
        <Box style={{ margin: "10px 10px" }}>
          <Button
            disableElevation
            variant="contained"
            className={clsx({
              [classes.activeFilter]: filter === "cancelled",
            })}
            onClick={() => {
              setFilter("cancelled");
              history.replace("/collected-orders?filter=cancelled");
            }}
          >
            CANCELLED
          </Button>
        </Box>
      </Box>
      <Grid container style={{ padding: theme.spacing(3) }}>
        <Grid
          style={{
            display: "flex",
            alignItems: "center",
            padding: "10px 0px",
          }}
          item
          xs={12}
          sm={5}
        >
          <Box style={{ paddingRight: "20px" }}>Search</Box>
          <TextField
            onChange={(e) => setSearchKey(e.target.value)}
            variant="outlined"
            style={{ width: "100%" }}
            inputProps={{
              style: { padding: "12px", backgroundColor: "white" },
            }}
            placeholder="Order Id or Mobile Number"
          />
        </Grid>
        <Grid
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row-reverse",
            padding: "10px 0px",
          }}
          item
          xs={7}
        >
          {/* <Button
            variant="contained"
            color="primary"
            onClick={() => {
          
            }}
          >
            Add Slider
          </Button> */}
        </Grid>

        <Grid item xs={12}>
          <Paper className={classes.root}>
            <Datatable
              getDataList={getOrderList}
              totalRecords={totalPages}
              loading={getBannersApiStatus.isPending}
              tableHeader={tableHeader}
            >
              {orderList.map((orderData, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell>
                      {moment(orderData.created_at).format("DD/MM/YYYY")}
                    </TableCell>
                    <TableCell>{orderData.order_id}</TableCell>
                    <TableCell>{orderData.name}</TableCell>
                    <TableCell>{orderData.items}</TableCell>
                    <TableCell>{orderData.mobile_number}</TableCell>
                    <TableCell>
                      <a
                        className={globalClasses.link}
                        style={{ color: "#2895c4" }}
                        onClick={() => {
                          if (filter == "placed")
                            history.push("/point-of-sale/add", orderData);
                          else
                            history.push(`/point-of-sale/${orderData.pos_id}`);
                        }}
                      >
                        View
                      </a>
                    </TableCell>
                  </TableRow>
                );
              })}
            </Datatable>
          </Paper>
        </Grid>
      </Grid>
      {/* <DetailView
            data={orderDetail}
            onBack={() => {
              setView("false");
              getOrderList();
            }}
          /> */}
    </>
  );
}

export default CollectedOrders;
