import { createReducer } from "@reduxjs/toolkit";
import {
  setConfigurations,
  setFormStructure,
  setFormValidations,
  setGeneralApiData,
  setLoader,
  setReducerData,
  setUserList,
  syncCacheData,
} from "./actions";
import { setOrderData } from "../actions";

const { SET_USER, IS_COMPONENT_BUSY } = require("../constants");

let initialState = {
  loaders: {
    listLoader: false,
    actionLoader: false,
  },
  userData: {
    permissions: [],
    businessDetails: {
      business_configurations: {
        tax_scheme: "",
      },
    },
  },
  form_structure: {
    fields: [],
    validations: {},
  },
  modal_status: false,
  form_validations: {},
  service_customers: {
    data: [],
  },
  states: [],
  cities: [],
  countries: [],
  isComponentBusy: false,
  billData: {
    items: [],
  },
  cache: {
    products: [],
  },
};

// const GeneralReducer = (state = initialState, action) => {
//   switch (action.type) {
//     case SET_USER:
//       return { ...state, userData: action.payload };
//     case IS_COMPONENT_BUSY:
//       return { ...state, isComponentBusy: action.payload };
//     default:
//       return state;
//   }
// };

const GeneralReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(SET_USER, (state, action) => {
      state.userData = action.payload;
    })
    .addCase(IS_COMPONENT_BUSY, (state, action) => {
      state.isComponentBusy = action.payload;
    })
    .addCase(setGeneralApiData, (state, action) => {
      let { data, key } = action.payload;
      state[key] = data;
    })
    .addCase(setReducerData, (state, action) => {
      let { data, property } = action.payload;
      state[property] = data;
    })
    .addCase(setFormStructure, (state, action) => {
      let { data } = action.payload;
      if (action.payload) state.form_structure = data;
    })
    .addCase(setUserList, (state, action) => {
      let { data, user_type } = action.payload;
      state[user_type] = data;
    })
    .addCase(setFormValidations, (state, action) => {
      state.form_validations = action.payload;
    })
    .addCase(setConfigurations, (state, action) => {
      let { key, value } = action.payload;
      state.userData.businessDetails.business_configurations[key] = value;
    })
    .addCase(syncCacheData, (state, action) => {
      let { key, data } = action.payload;
      state.cache[key] = data;
    })
    .addCase(setLoader,(state,action)=>{
      console.log("SetLaoder")
      let { key, data } = action.payload;
      state.loaders[key] = data;
    })
    .addCase(setOrderData, (state, action) => {
      let { data } = action.payload;
      state.billData = data;
    })
    .addDefaultCase((state, action) => state);
});

export default GeneralReducer;
