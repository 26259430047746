import { createAction } from "@reduxjs/toolkit";
import {
  FETCH_CITIES_DATA,
  FETCH_COUNTRY_DATA,
  FETCH_STATE_DATA,
  GENERAL_API_CALL_DATA,
  SET_REDUCER_DATA,
  SET_USER,
  TYPES,
  UPDATE_BUSINESS_CONFIGURATION,
} from "../constants";
import { GENERAL_API_CALL } from "../constants";

export const setUser = (payload) => {
  return {
    type: SET_USER,
    payload,
  };
};

export const fetchCountries = createAction(FETCH_COUNTRY_DATA);
export const fetchCities = createAction(FETCH_CITIES_DATA);
export const fetchStates = createAction(FETCH_STATE_DATA);
export const setReducerData = createAction(SET_REDUCER_DATA);
export const generalApiCall = createAction(GENERAL_API_CALL);
export const setGeneralApiData = createAction(GENERAL_API_CALL_DATA);
export const fetchUser = createAction(TYPES.FETCH_USER_DATA);
export const updateBusinessConfigurations = createAction(
  UPDATE_BUSINESS_CONFIGURATION
);

export const getFormStructure = createAction(TYPES.GET_FORM_STRUCTURE);
export const setFormStructure = createAction(TYPES.SET_FORM_STRUCTURE);
export const setUserList = createAction(TYPES.SET_USER_LIST);
export const setFormValidations = createAction(TYPES.SET_FORM_VALIDATIONS);

export const addCustomer = createAction(TYPES.ADD_CUSTOMER);
export const fetchUserList = createAction(TYPES.FETCH_USERS_LIST);
export const updateCustomer = createAction(TYPES.UPDATE_CUSTOMER);

export const updateConfigurations = createAction(TYPES.UPDATE_CONFIGURATIONS);
export const setConfigurations = createAction(TYPES.SET_CONFIGURATIONS);

export const syncCacheData = createAction(TYPES.SYNC_CACHE_DATA);

export const setLoader= createAction(TYPES.SET_LOADER)