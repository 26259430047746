import { createAction } from "@reduxjs/toolkit";
import { TYPES } from "./constants";

export * from "./category/actions";
export * from "./user/actions";

export const getCustomerById = createAction(TYPES.GET_CUSTOMER_BY_ID);
export const setCustomerById = createAction(TYPES.SET_CUSTOMER_BY_ID);

export const getServiceBills = createAction(TYPES.FETCH_SERVICE_BILLS);
export const setServiceBills = createAction(TYPES.SET_SERVICE_BILLS);

export const updateServiceBillTransaction = createAction(
  TYPES.UPDATE_SERVICE_BILL_TRANSACTION
);
export const getOrderById = createAction(TYPES.GET_ORDER_BY_ID);
export const getOnlineOrderById = createAction(TYPES.GET_ONLINE_ORDER_BY_ID);
export const setOrderData = createAction(TYPES.SET_ORDER_BY_ID);
export const deletePOSBill = createAction(TYPES.DELETE_POS_BILL);
