import { createSelector } from "@reduxjs/toolkit";

export const selectCountries = (state) => state.general.countries;
export const selectStates = (state) => state.general.states;
export const selectCities = (state) => state.general.cities;

export const selectGeneralReducer = (state) => state.general;
export const selectBillingReducer = (state) => state.billing;
export const selectOrderData = createSelector(selectGeneralReducer, (state) => {
  return state.billData;
});

export const selectCache = (table) => {
  return createSelector(selectGeneralReducer, (state) => {
    return state.cache[table];
  });
};

export const selectLoadingState = (type) => {
  return createSelector(selectGeneralReducer, (state) => {
    return state?.loaders[type];
  });
};

export const selectFormValidation = createSelector(
  selectGeneralReducer,
  (state) => state.form_validations
);
export const selectModalStatus = createSelector(
  selectGeneralReducer,
  (state) => state.modal_status
);

export const selectServiceCustomer = createSelector(
  selectGeneralReducer,
  (state) => state.service_customers
);

export const selectFormStructure = (table_name) => {
  return createSelector(
    selectGeneralReducer,
    (state) => state.form_structure[table_name]
  );
};

export const selectServiceBills = createSelector(
  selectBillingReducer,
  (state) => state.service_bills
);

export const selectBillableCustomer = createSelector(
  selectBillingReducer,
  (state) => state.customer_data
);
