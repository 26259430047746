import React, { useCallback, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import {
  Box,
  Grid,
  IconButton,
  Link,
  Menu,
  MenuItem,
  TableCell,
  TableRow,
  useTheme,
} from "@material-ui/core";
import Datatable from "../../MuiComponent/Datatable";
import CloseIcon from "@material-ui/icons/Close";
import useCallbackStatus from "../../hooks/useCallbackStatus";
import useCancelRequest from "../../hooks/useCancelToken";
import { apiClient } from "../../config/apiClient";
import { useAuth } from "../../providers/AuthProvider";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import { ceil, values } from "lodash";
import moment from "moment";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import Modal from "../../MuiComponent/Modal";
import TextField from "../../MuiComponent/TextField";
import useGeneralStyle from "../../assets/css/general";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import { useSnackbar } from "notistack";
import { httpClient } from "../../config/httpClient";
import { posFormValidation } from "../../validations/login-validate";
import { useFormik } from "formik";

const tableHeader = [
  { id: "name", align: "left", label: "REGISTERED DATE", minWidth: 170 },
  {
    id: "population",
    label: "USER NAME",
    minWidth: 170,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "density",
    label: "MOBILE",
    minWidth: 170,
    align: "left",
    format: (value) => value.toFixed(2),
  },
  {
    id: "density",
    label: "EMAIL",
    minWidth: 170,
    align: "left",
    format: (value) => value.toFixed(2),
  },
  // {
  //   id: "detailed_profile",
  //   label: "DETAILED PROFILE",
  //   minWidth: 170,
  //   align: "left",
  //   format: (value) => value.toFixed(2),
  // },
  {
    id: "density",
    label: "MANAGE",
    minWidth: 170,
    align: "left",
    format: (value) => value.toFixed(2),
  },
];

const useStyles = makeStyles((theme2) => ({
  root: {
    width: "100%",
    marginTop: "25px",
    overflowX: "auto",
    backgroundColor: "white",
    border: "1px solid #caced5",
  },
  selectInput: {
    marginLeft: "20px",
    "& .MuiOutlinedInput-input": {
      padding: "12.5px 14px",
      width: "150px",
    },
  },
  container: {
    height: "440px",
    width: "100%",
  },
  activeFilter: {
    backgroundColor: theme2.palette.primary.main,
    color: "white",
    fontWeight: 600,
  },
}));

export default function POSDataTable({ userType }) {
  const classes = useStyles();
  const [totalPages, setTotalPages] = React.useState(10);
  const [data, setData] = React.useState([]);
  const [search, setSearchKey] = useState("");
  const [modal, setModal] = React.useState(false);
  const globalClasses = useGeneralStyle();
  const getAllUserApiStatus = useCallbackStatus();
  const manageUserApiStatus = useCallbackStatus();
  const getAllExpertsApiStatus = useCallbackStatus();
  const apiSource = useCancelRequest();
  const [userObj, setUserObject] = useState({});
  const [suspendReason, setSuspendReason] = useState("");
  const auth = useAuth();
  const notification = useSnackbar();
  const history = useHistory();
  const [filter, setFilter] = React.useState(
    _.isUndefined(history.location.state)
      ? "not_verified"
      : history.location.state.filter
  );
  const theme = useTheme();

  const {
    touched,
    values,
    initialValues,
    errors,
    validateForm,
    resetForm,
    setValues,
    setFieldValue,
    submitForm,
    handleChange,
  } = useFormik({
    initialValues: {
      name: "",
      mobile_number: "",
      email: "",
    },
    validationSchema: posFormValidation,
    onSubmit: () => {
      updateData();
      // history.replace("/point-of-sale/add", values);
    },
  });

  const ModalHeader = () => {
    return (
      <>
        <Box
          display="flex"
          justifyContent="space-between"
          style={{ fontSize: "14px" }}
        >
          <Box textAlign="left" style={{ fontWeight: 600 }} padding={1}>
            Add Bill
          </Box>
          <IconButton
            style={{ position: "absolute", right: "20px" }}
            onClick={() => {
              setModal(!modal);
            }}
          >
            <CloseIcon size="small" />
          </IconButton>
        </Box>
      </>
    );
  };

  const getAllUserList = useCallback(
    async (pageSize = 10, pageNumber = 1, search) => {
      const apiBody = {
        page: pageNumber,
        pageSize: pageSize,
        search_string: search,
        user_type: "walkin",
        business_id: auth.userData.business_id,
      };

      if (apiBody.search_string === "") {
        delete apiBody.search_string;
      }

      await getAllUserApiStatus.run(
        httpClient("POST", "/partner/getUsers", {
          body: apiBody,
        }).then((result) => {
          console.log("Result: ", result);

          const {
            data: { data, total },
          } = result;

          console.log("TOTAL RECOREDS: ", total);
          console.log("TOTAL DATA: ", data);
          setData(data);
          setTotalPages(total);
        })
      );
    },
    []
  );
  // const getAllUserList = async (pageSize = 10, pageNumber = 1) => {
  //   const apiBody = {
  //     admin_id: auth.getUserId(),
  //     page_size: pageSize,
  //     search_string: search,
  //     page_number: pageNumber
  //   }

  //   if (apiBody.search_string === '') {
  //     delete apiBody.search_string
  //   }

  //   const result = await getAllUserApiStatus.run(
  //     apiClient("POST", 'admin', 'listallusers', {
  //       body: apiBody,
  //       shouldUseDefaultToken: false,
  //       cancelToken: apiSource.token,
  //       enableLogging: true
  //     }).then(result => {
  //       const { content: { data, totalPages } } = result
  //       console.log("GEt All Users : ", result)
  //       setTotalPages(totalPages)
  //       setData(data)
  //     }).catch(error => {
  //       console.log('Error in DocumentDatatable.js')
  //       notification.enqueueSnackbar(error.message, {
  //         variant: 'error',
  //       });
  //     })
  //   )
  // }

  const updateData = async () => {
    await manageUserApiStatus.run(
      httpClient("PUT", `/partner/updateWalkinCustomerProfile/${values.id}`, {
        body: values,
      }).then((result) => {
        resetForm();
        setModal(!modal);
        getAllUserList();
        console.log("Result: ", result);
      })
    );
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      getAllUserList(10, 1, search);
    }, 300);

    return () => clearTimeout(delayDebounceFn);
  }, [search]);

  const viewProfile = async (id, userType) => {
    await manageUserApiStatus.run(
      apiClient("POST", "common", "getuserprofilebyid", {
        body: {
          user_id: id,
          entity_type: userType,
        },
        shouldUseDefaultToken: false,
        cancelToken: apiSource.token,
        enableLogging: true,
      }).then((result) => {
        let {
          content: { data },
        } = result;
        history.replace("/users/view-profile", {
          filter: filter,
          userData: data,
          from: "/users",
          userType: userType,
        });
      })
    );
  };

  return (
    <>
      <Modal
        headerComponent={ModalHeader}
        height="350px"
        width="600px"
        loading={manageUserApiStatus.isPending}
        closeBtnText="Cancel"
        saveBtnText="Update"
        saveHandler={submitForm}
        closeHandler={() => {
          setModal(!modal);
        }}
        status={modal}
      >
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Box style={{ padding: "10px 0px" }}>Name</Box>
            <TextField
              name="name"
              onChange={handleChange}
              placeholder="Write Here"
              value={values.name}
              error={touched.name && errors.name ? true : false}
              helperText={touched.name && errors.name}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={6}>
            <Box style={{ padding: "10px 0px" }}>Contact Number</Box>
            <TextField
              name="mobile_number"
              onChange={handleChange}
              error={
                touched.mobile_number && errors.mobile_number ? true : false
              }
              helperText={touched.mobile_number && errors.mobile_number}
              value={values.mobile_number}
              placeholder="Write Here"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={6}>
            <Box style={{ padding: "10px 0px" }}>Email</Box>
            <TextField
              name="email"
              onChange={handleChange}
              value={values.email}
              error={touched.email && errors.email ? true : false}
              helperText={touched.email && errors.email}
              placeholder="Write Here"
              variant="outlined"
            />
          </Grid>
        </Grid>
      </Modal>
      <Grid container style={{ padding: theme.spacing(3) }}>
        <Grid
          style={{ display: "flex", alignItems: "center", padding: "10px 0px" }}
          item
          xs={12}
          md={4}
        >
          <Box
            className={globalClasses.filterLabel}
            style={{ paddingRight: "20px" }}
          >
            Search
          </Box>
          <TextField
            onChange={(e) => setSearchKey(e.target.value)}
            variant="outlined"
            style={{ width: "100%" }}
            inputProps={{
              style: { padding: "12px", backgroundColor: "white" },
            }}
            placeholder="by Name & Mobile Number"
          />
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.root}>
            <Datatable
              loading={getAllUserApiStatus.isPending}
              getDataList={getAllUserList}
              totalRecords={totalPages}
              tableHeader={tableHeader}
            >
              {data.map((row, index) => {
                return (
                  <TableRow key={index} hover>
                    <TableCell>
                      {moment(row.created_at).format("DD/MM/YYYY")}
                    </TableCell>
                    <TableCell>
                      {row.name} {row.last_name}
                    </TableCell>
                    <TableCell>{row.mobile_number}</TableCell>
                    <TableCell>{row.email}</TableCell>
                    {/* <TableCell>
                      <Link
                        className={globalClasses.link}
                        onClick={() => viewProfile(row.id, row.user_type)}
                      >
                        View
                      </Link>
                    </TableCell> */}
                    <TableCell>
                      <Box>
                        <PopupState variant="popover" popupId="demo-popup-menu">
                          {(popupState) => (
                            <React.Fragment>
                              <IconButton {...bindTrigger(popupState)}>
                                <MoreHorizIcon />
                              </IconButton>
                              <Menu {...bindMenu(popupState)}>
                                <MenuItem
                                  onClick={() => {
                                    setValues(row);
                                    setModal(!modal);
                                    popupState.setOpen(false);
                                  }}
                                >
                                  Edit
                                </MenuItem>
                              </Menu>
                            </React.Fragment>
                          )}
                        </PopupState>
                      </Box>
                    </TableCell>
                  </TableRow>
                );
              })}
            </Datatable>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}
