import * as yup from "yup";
import _ from "lodash";

const loginValidation = yup.object().shape({
  email: yup
    .string()
    .required("email is required")
    .typeError("email is required"),
  password: yup
    .string()
    .required("password is required")
    .typeError("password is required"),
});

const titleValidate = yup.object().shape({
  title: yup
    .string()
    .trim()
    .required("Title is required")
    .typeError("Title is required"),
});

const managePolicyValidate = yup.object().shape({
  // role: yup
  //   .string()
  //   .trim()
  //   .required("Role name is required")
  //   .typeError("Role name is required"),
  policy: yup
    .array()
    .min(1, "Select atleast 1 policy")
    // .max(3, "Max 3 category allowed")
    .required("Policy is required")
    .typeError("Policy is required"),
});

const forgotPasswordValidation = yup.object().shape({
  email: yup
    .string()
    // .email()
    .required("Enter valid details")
    .typeError("Enter valid details"),
});

const resetPasswordValidation = yup.object().shape({
  password: yup
    .string()
    .required("password is required")
    .typeError("password is required"),
  confirm_password: yup
    .string()
    .required("Confirm password is required")
    .oneOf([yup.ref("password"), null], "passwords must match"),
});

const otpValidation = yup.object().shape({
  otp: yup.string().required("Invalid Otp").typeError("Invalid Otp"),
});

const accountDetailsValidation = yup.object().shape({
  bank_name: yup
    .string()
    .required("Bank name is required")
    .typeError("Bank name is required"),
  account_holder_name: yup
    .string()
    .required("Account holder name is required")
    .typeError("Account holder name is required"),
  account_number: yup
    .number()
    .required("Account Number is required")
    .typeError("Account Number is required"),
  ifsc_code: yup
    .string()
    .required("IFSC Code is required")
    .typeError("IFSC Code is required"),
});

const businessProfileValidation = yup.object().shape({
  name: yup.string().required("Name is Required").typeError("Name is Required"),
  email: yup
    .string()
    .required("Email is Required")
    .typeError("Email is Required"),
  gst_number: yup
    .string()
    .nullable()
    // .required("GST Number is Required")
    // .typeError("GST Number is Required")
    .matches(
      "^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$",
      "Enter valid GST number"
    ),
  pancard: yup
    .string()
    .nullable()
    // .required("PAN Number is Required")
    // .typeError("PAN Number is Required")
    .matches("[A-Z]{5}[0-9]{4}[A-Z]{1}", "Enter valid PAN numeber"),
  contact_number: yup
    .string()
    .required("Contact Number is Required")
    .typeError("Contact Number is Required"),
  address: yup
    .string()
    .required("Address is Required")
    .typeError("Address is Required"),
  state: yup
    .string()
    .required("State is Required")
    .typeError("State is Required"),
  city: yup.string().required("City is Required").typeError("City is Required"),
  pincode: yup
    .string()
    .required("Pincode is Required")
    .typeError("Pincode is Required"),
});

const adminProfileForm = yup.object().shape({
  first_name: yup
    .string()
    .required("First Name is Required")
    .typeError("First Name is Required"),
  last_name: yup
    .string()
    .required("Last Name is Required")
    .typeError("Last Name is Required"),
  contact_number: yup
    .string()
    .required("Phone Number is Required")
    .typeError("Phone Number is Required"),
  // age: yup.number().required("Age is Required").typeError("Age is Required"),
  // gender: yup
  //   .string()
  //   .required("Gender is invalid")
  //   .oneOf(["male", "female", "other"]),
});

const changePasswordValidate = yup.object().shape({
  old_password: yup
    .string()
    .required("Current Password is Required")
    .typeError("Current Password is Required"),
  new_password: yup
    .string()
    .required("New Password is Required")
    .typeError("New Password is Required"),
  confirm_password: yup
    .string()
    .oneOf(
      [yup.ref("new_password"), null],
      "Confirm password must be match with new password"
    )
    .typeError("Confirm password must be match with new password"),
});

const editAreaValidate = yup.object().shape({
  areaName: yup
    .string()
    .required("Area Name is Required")
    .typeError("Area Name is Required"),
});

const addAdvertisementValidation = yup.object().shape({
  title: yup
    .string()
    .required("Title is Required")
    .typeError("Title is Required"),
  law_area_id: yup
    .string()
    .required("Area is Required")
    .typeError("Area is Required"),
  image_name: yup
    .mixed()
    .required("Image is Required")
    .typeError("Only the following formats are accepted: .jpeg, .jpg .bmp .png")
    .test(
      "type",
      "Only the following formats are accepted: .jpeg, .jpg .bmp .png",
      (value) => {
        return (
          value &&
          (value.type === "image/jpeg" ||
            value.type === "image/bmp" ||
            value.type === "image/png" ||
            value.type === "image/jpg")
        );
      }
    ),
  redirect_url: yup
    .string()
    .required("Redirect Url is Required")
    .typeError("Redirect Url is Required"),
});

const inviteAdminValidation = yup.object().shape({
  email: yup
    .string()
    .required("email is required")
    .typeError("email is required"),
  role: yup.mixed().required("role is required").typeError("role is required"),
});

const posFormValidation = yup.object().shape({
  mobile_number: yup
    .string()
    .required("mobile number is required")
    .max(10, "mobile number must be of 10 numbers")
    .min(10, "mobile number must be of 10 numbers"),
  // business_id:yup.string().optional()
});

const customerDataValidation = yup.object().shape({
  name: yup.string().required("name is required").typeError("name is required"),
  mobile_number: yup
    .string()
    .required("contact number is required")
    .typeError("contact number is required"),
  email: yup
    .string()
    .required("email is required")
    .email()
    .typeError("email is required"),
});

const suplliersValidation = yup.object().shape({
  business_name: yup
    .string()
    .required("business name is required")
    .typeError("business name is required"),
  name: yup.string().required("name is required").typeError("name is required"),
  contact_number: yup
    .string()
    .required("contact number is required")
    .typeError("contact number is required"),
  alternate_number: yup
    .string()
    .required("alternate number is required")
    .typeError("alternate number is required"),
  // gst_number: yup
  //   .string()
  //   .required("gst number is required")
  //   .typeError("gst number is required"),
  address: yup
    .string()
    .required("address is required")
    .typeError("address is required"),
  city_id: yup
    .string()
    .required("city is required")
    .typeError("city is required"),
  state_id: yup
    .string()
    .required("state is required")
    .typeError("state is required"),
  country_id: yup
    .string()
    .required("country is required")
    .typeError("country is required"),
});

const adminRegistrationValidation = yup.object().shape({
  first_name: yup
    .string()
    .required("Name is required")
    .typeError("Name is required"),
  last_name: yup
    .mixed()
    .required("Last name is required")
    .typeError("Last name is required"),
  contact_number: yup
    .mixed()
    .required("Phone number is required")
    .typeError("Phone number is required"),
  password: yup
    .mixed()
    .required("password is required")
    .typeError("password is required"),
  confirm_password: yup
    .string()
    .oneOf([yup.ref("password"), null], "password must match")
    .typeError("password must match"),
});

const cmsContactTabValidation = yup.object().shape({
  phone: yup
    .string()
    .required("phone number is required")
    .typeError("phone number is required"),
  email: yup
    .string()
    .required("email is required")
    .email()
    .typeError("email is required"),
});

const cmsAboutTabValidation = yup.object().shape({
  about: yup
    .string()
    .required("about is required")
    .typeError("about is required"),
});

const cmsPrivacyPolicyValidation = yup.object().shape({
  policy: yup
    .string()
    .required("policy is required")
    .typeError("policy is required"),
});

const cmsTermsValidation = yup.object().shape({
  terms: yup
    .string()
    .required("terms and conditions is required")
    .typeError("terms and conditions is required"),
});

const faqCategoryValidation = yup.object().shape({
  name: yup.string().required("name is required").typeError("name is required"),
});

// const inviteAdminValidation = yup.object().shape({
//   email: yup
//     .string()
//     .required("email is required")
//     .typeError("email is required"),
// });

const faqQuestionFormValidation = yup.object().shape({
  category_id: yup
    .string()
    .required("please select category")
    .typeError("please select category"),
  question: yup
    .string()
    .required("question is required")
    .typeError("question is required"),
  answer: yup
    .string()
    .required("answer is required")
    .typeError("answer is required"),
});

const addCategoryValidation = yup.object().shape({
  name: yup
    .string()
    .required("cateogory name is required")
    .typeError("cateogory name is required"),
  image_name: yup
    .string()
    .required("image is required")
    .typeError("image is required"),
  image: yup
    .mixed()
    .required("Image is required")
    .test(
      "fileSize",
      "Image too large allowed size is less then 1Mb",
      (value) => {
        if (value && typeof value == "object") return value.size <= 1000000;
        else return true;
      }
    ),
  // parent_category_id:yup.string().required('please select ').typeError("question is required"),
});

const addCountryValidation = yup.object().shape({
  name: yup
    .string()
    .required("country name is required")
    .typeError("country name is required"),
  // parent_category_id:yup.string().required('please select ').typeError("question is required"),
});

const addCityValidation = yup.object().shape({
  name: yup.string().required("name is required").typeError("name is required"),
  state_id: yup
    .string()
    .required("please select state")
    .typeError("please select state"),
});

const couponValidation = yup.object().shape({
  name: yup.string().required("name is required").typeError("name is required"),
  code: yup.string().required("code is required").typeError("code is required"),
  type: yup.string().required("type is required").typeError("type is required"),
  discount: yup
    .number()
    .required("discount is required")
    .typeError("discount is required"),
  start_date: yup
    .string()
    .required("start date is required")
    .typeError("start date is required"),
  end_date: yup
    .string()
    .required("end date is required")
    .typeError("end date is required"),
  min_spend: yup
    .number()
    .required("min speed is required")
    .typeError("min speed is required"),
  user_limit: yup
    .number()
    .required("users limit is required")
    .typeError("uses limit is required"),
  limit_per_user: yup
    .number()
    .required("Uses limit / per user is required")
    .typeError("Uses limit / per user is required"),
  email: yup
    .string()
    .required("email is required")
    .typeError("email is required"),
  country: yup
    .string()
    .required("country is required")
    .typeError("country is required"),
  coupon_status: yup
    .string()
    .required("status is required")
    .typeError("status is required"),
});

const posSalesBillValidation = yup.object().shape({
  customer_name: yup
    .string()
    .required("Supplier is required")
    .typeError("Supplier is required"),
  products: yup
    .array()
    .min(1)
    // .of(
    //   yup.object().shape({
    //     quantity: yup
    //       .number()
    //       .min(1, "atleast 1 unit is required")
    //       .required("quantity is required"),
    //     unit_cost: yup
    //       .number()
    //       .min(1, "unit cost is required")
    //       .required("unit cost is required"),
    //   })
    // )
    .required("product is required"),
});

const addBillValidation = yup.object().shape({
  supplier_id: yup
    .string()
    .required("Supplier is required")
    .typeError("Supplier is required"),
  purchase_date: yup
    .string()
    .required("purchase date is required")
    .typeError("purchase date is required"),
  discount_value: yup
    .number()
    .required("discount value is required")
    .typeError("discount value is required"),
  products: yup
    .array()
    .min(1)
    .of(
      yup.object().shape({
        quantity: yup
          .number()
          .min(1, "atleast 1 unit is required")
          .required("quantity is required"),
        unit_cost: yup
          .number()
          .min(1, "unit cost is required")
          .required("unit cost is required"),
      })
    )
    .required("product is required"),
  // reference_number: yup
  //   .string()
  //   .required("Reference Number is required")
  //   .typeError("Reference Number is required"),
  // shipping_details: yup
  //   .string()
  //   .required("shipping details is required")
  //   .typeError("shipping details is required"),
  // shipping_cost: yup
  //   .string()
  //   .required("shipping cost is required")
  //   .typeError("shipping cost is required"),
  // additional_note: yup
  //   .string()
  //   .required("additional note is required")
  //   .typeError("additional note is required"),
});

yup.addMethod(yup.array, "firstElementNotEmpty", function (errorMessage) {
  return this.test(
    `check-first-element-not-none`,
    errorMessage,
    function (value) {
      const { path, createError } = this;
      return (
        (value && value[0] !== "none") ||
        createError({ path, message: errorMessage })
      );
    }
  );
});

const addProductValidation = yup.object().shape({
  images: yup
    .array()
    .required("image is required")
    .min(1)
    .test("fileSize", "Image too large allowed size less then 1Mb", (value) => {
      return value.every((item) => {
        console.log("image VALUE item1: ", item);
        if (item instanceof File) {
          console.log(
            "image VALUE item1 inside Fule: ",
            item,
            item.size <= 1000000 ? "truie" : "false"
          );

          return item.size <= 1000000;
        } else return true;
      });
    }),
  name: yup
    .string()
    .required("product name is required")
    .typeError("product name is required")
    .min(2, "name is too short - should be 2 character minimum")
    .max(60, "Too Long!"),
  categories: yup
    .array()
    .firstElementNotEmpty("select at least one category")
    .required("category is required")
    .typeError("category is required")
    .min(1),
  // short_description: yup
  //   .string()
  //   .required("description is required")
  //   .typeError("description is required")
  //   .min(4, "description is too short - should be 4 character minimum"),
  // description: yup
  //   .string()
  //   .required("description is required")
  //   .typeError("description is required")
  //   .min(4, "description is too short - should be 4 character minimum"),
  pos_price: yup.number().required("pos price is required"),
  tax: yup.number().required("tax is required").max(100),
  barcode: yup.string().required("barcode is required"),
  quantity: yup
    .number()
    .required("quantity is required")
    .test(
      "maxDigitsAfterDecimal",
      "number field must have 1 digits after decimal or less",
      (number) => /^\d+(\.\d{0,1})?$/.test(number)
    ),

  offer_price: yup.number().required("offer price is required"),
  retail_price: yup
    .number()
    .when("offer_price", (offer_price, schema) => {
      return schema.test({
        test: (retail_price) => !!offer_price && retail_price > offer_price,
        message: "Max should be > min",
      });
    })
    .required("retail price is required"),
});

const addProductValidationWithLimitedFields = yup.object().shape({
  images: yup
    .array()
    .required("image is required")
    .min(1)
    .test("fileSize", "Image too large allowed size less then 1Mb", (value) => {
      return value.every((item) => {
        console.log("image VALUE item1: ", item);
        if (item instanceof File) {
          console.log(
            "image VALUE item1 inside Fule: ",
            item,
            item.size <= 1000000 ? "truie" : "false"
          );

          return item.size <= 1000000;
        } else return true;
      });
    }),
  name: yup
    .string()
    .required("product name is required")
    .typeError("product name is required")
    .min(2, "name is too short - should be 2 character minimum")
    .max(60, "Too Long!"),
  categories: yup
    .array()
    .firstElementNotEmpty("select at least one category")
    .required("category is required")
    .typeError("category is required")
    .min(1),
  // short_description: yup
  //   .string()
  //   .required("description is required")
  //   .typeError("description is required")
  //   .min(4, "description is too short - should be 4 character minimum"),
  // description: yup
  //   .string()
  //   .required("description is required")
  //   .typeError("description is required")
  //   .min(4, "description is too short - should be 4 character minimum"),
  hsn: yup.number().required("HSN Code is required"),
  pos_price: yup.number().required("pos price is required"),
  weight: yup.number().required("Weight is required"),
  length: yup.number().required("Length is required"),
  width: yup.number().required("Length is required"),
  height: yup.number().required("Height is required"),
  tax: yup.number().required("tax is required").max(100),
  barcode: yup.string().required("barcode is required"),
  // quantity: yup
  //   .number()
  //   .required("quantity is required")
  //   .test(
  //     "maxDigitsAfterDecimal",
  //     "number field must have 1 digits after decimal or less",
  //     (number) => /^\d+(\.\d{0,1})?$/.test(number)
  //   ),

  // offer_price: yup.number().required("offer price is required"),
  // retail_price: yup
  //   .number()
  //   .when("offer_price", (offer_price, schema) => {
  //     return schema.test({
  //       test: (retail_price) => !!offer_price && retail_price > offer_price,
  //       message: "Max should be > min",
  //     });
  //   })
  // .required("retail price is required"),
});
// parent_category_id:yup.string().required('please select ').typeError("question is required"),

const addBannerValidation = yup.object().shape({
  name: yup.string().required("name is required").typeError("name is required"),
  category_id: yup
    .string()
    .required("category is required")
    .typeError("category is required"),
  link: yup.string().required("link is required").typeError("link is required"),
  image: yup
    .mixed()
    .required("Image is Required")
    .typeError("Only the following formats are accepted: .jpeg, .jpg .bmp .png")
    .test(
      "type",
      "Only the following formats are accepted: .jpeg, .jpg .bmp .png",
      (value) => {
        return (
          value &&
          (value.type === "image/jpeg" ||
            value.type === "image/bmp" ||
            value.type === "image/png" ||
            value.type === "image/jpg")
        );
      }
    ),
  type: yup.string().required("invalid type").typeError("invalid type"),
  active: yup.string().required("invalid type").typeError("invalid type"),
});

const editBannerValidation = yup.object().shape({
  name: yup.string().required("name is required").typeError("name is required"),
  category_id: yup
    .string()
    .required("category is required")
    .typeError("category is required"),
  link: yup.string().required("link is required").typeError("link is required"),
  image_name: yup
    .mixed()
    .test(
      "type",
      "Only the following formats are accepted: .jpeg, .jpg .png",
      function (value) {
        const url = yup.ref("image");
        if (
          (!_.isNull(this.resolve(url)) || !_.isUndefined(this.resolve(url))) &&
          typeof value == "string"
        )
          return true;
        else
          return (
            value &&
            (value.type === "image/jpeg" ||
              value.type === "image/png" ||
              value.type === "image/jpg")
          );
      }
    ),
  type: yup.string().required("invalid type").typeError("invalid type"),
  active: yup.string().required("invalid type").typeError("invalid type"),
});

const addSliderValidation = yup.object().shape({
  name: yup.string().required("name is required").typeError("name is required"),
  link: yup.string().required("link is required").typeError("link is required"),
  image: yup
    .mixed()
    .required("Image is Required")
    .typeError("Only the following formats are accepted: .jpeg, .jpg .bmp .png")
    .test(
      "type",
      "Only the following formats are accepted: .jpeg, .jpg .bmp .png",
      (value) => {
        return (
          value &&
          (value.type === "image/jpeg" ||
            value.type === "image/bmp" ||
            value.type === "image/png" ||
            value.type === "image/jpg")
        );
      }
    ),
  type: yup.string().required("invalid type").typeError("invalid type"),
  active: yup.string().required("invalid type").typeError("invalid type"),
});

const addServiceValidation = yup.object().shape({
  name: yup.string().required("name is required").typeError("name is required"),
  status: yup
    .string()
    .oneOf(["active", "inactive"], "Please select valid value")
    .required("Please select valid value")
    .typeError("Please select valid value"),
});

const editSliderValidation = yup.object().shape({
  name: yup.string().required("name is required").typeError("name is required"),
  link: yup.string().required("link is required").typeError("link is required"),
  image_name: yup
    .mixed()
    .test(
      "type",
      "Only the following formats are accepted: .jpeg, .jpg .png",
      function (value) {
        const url = yup.ref("image");
        if (
          (!_.isNull(this.resolve(url)) || !_.isUndefined(this.resolve(url))) &&
          typeof value == "string"
        )
          return true;
        else
          return (
            value &&
            (value.type === "image/jpeg" ||
              value.type === "image/png" ||
              value.type === "image/jpg")
          );
      }
    ),
  type: yup.string().required("invalid type").typeError("invalid type"),
  active: yup.string().required("invalid type").typeError("invalid type"),
});

export const generateYupSchema = (laravelSyntax) => {
  let yupSchema = {};
  Object.keys(laravelSyntax).forEach((field) => {
    if (laravelSyntax[field].includes("required")) {
      yupSchema[field] = yup.string().required("This field is required");
    } else {
      yupSchema[field] = yup.string();
    }
    // You can add more conditions based on other Laravel validation rules
    // For example, for 'email' validation, you can add Yup.string().email() condition.
  });

  return yup
    .object()
    .shape({ form_input: yup.object().shape(yupSchema).nullable() });
};

export {
  addSliderValidation,
  editSliderValidation,
  editBannerValidation,
  addBannerValidation,
  addCityValidation,
  couponValidation,
  addCountryValidation,
  addProductValidation,
  addProductValidationWithLimitedFields,
  addCategoryValidation,
  faqQuestionFormValidation,
  posSalesBillValidation,
  faqCategoryValidation,
  cmsTermsValidation,
  cmsPrivacyPolicyValidation,
  cmsAboutTabValidation,
  cmsContactTabValidation,
  adminRegistrationValidation,
  inviteAdminValidation,
  addAdvertisementValidation,
  loginValidation,
  suplliersValidation,
  forgotPasswordValidation,
  businessProfileValidation,
  addBillValidation,
  otpValidation,
  resetPasswordValidation,
  adminProfileForm,
  customerDataValidation,
  posFormValidation,
  changePasswordValidate,
  editAreaValidate,
  managePolicyValidate,
  accountDetailsValidation,
  titleValidate,
  addServiceValidation,
};
